import { Tooltip } from "@material-ui/core";
import React from "react";

const UserTooltip = ({ country, userCount, children }) => {
  const positionRef = React.useRef({
    x: 0,
    y: 0,
  });

  const popperRef = React.useRef(null);
  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.scheduleUpdate();
    }
  };

  return (
    <Tooltip
      title={`${country}: ${userCount} ${userCount === 1 ? "user" : "users"}`}
      onMouseMove={handleMouseMove}
      PopperProps={{
        popperRef,
        anchorEl: {
          clientHeight: 0,
          clientWidth: 0,
          getBoundingClientRect: () => ({
            top: positionRef.current.y,
            left: positionRef.current.x,
            right: positionRef.current.x,
            bottom: positionRef.current.y + 5,
            width: 0,
            height: 0,
          }),
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default UserTooltip;
