import {
  CircularProgress,
  Fade,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  text: {
    marginTop: theme.spacing(1),
  },
}));

const Loading = () => {
  const classes = useStyles();
  return (
    <Fade in timeout={1000}>
      <div className={classes.root}>
        <CircularProgress />
        <Typography variant="body2" className={classes.text}>
          Loading...
        </Typography>
      </div>
    </Fade>
  );
};

export default Loading;
