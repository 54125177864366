import * as React from "react";
import { createContext, ReactNode, useEffect, useState } from "react";

export interface IUser {
  passport_id: string;
  persona_id: string;
  artist_name: string;
  country_alpha2: string;
  city: string | null;
}

export type MapContextType = {
  users: IUser[];
  countryCounts: { [country_alpha2: string]: number };
  total: number;
};

export const MapContext = createContext<MapContextType | null>(null);
interface MapProviderProps {
  children: ReactNode;
}

export const MapDataProvider: React.FC<MapProviderProps> = ({ children }) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [countryCounts, setCountryCounts] = useState<{
    [country_alpha2: string]: number;
  }>({});
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const api = `${process.env.REACT_APP_API_BASE}/map/`;
    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        setUsers(data.users);
        setCountryCounts(data.country_counts);
        setTotal(data.total);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <MapContext.Provider value={{ users, countryCounts, total }}>
      {children}
    </MapContext.Provider>
  );
};
