export const countries = [
  ["004", "Afghanistan"],
  ["008", "Albania"],
  ["012", "Algeria"],
  ["024", "Angola"],
  ["010", "Antarctica"],
  ["032", "Argentina"],
  ["051", "Armenia"],
  ["036", "Australia"],
  ["040", "Austria"],
  ["031", "Azerbaijan"],
  ["044", "Bahamas"],
  ["050", "Bangladesh"],
  ["112", "Belarus"],
  ["056", "Belgium"],
  ["084", "Belize"],
  ["204", "Benin"],
  ["064", "Bhutan"],
  ["068", "Bolivia"],
  ["070", "Bosnia and Herz."],
  ["072", "Botswana"],
  ["076", "Brazil"],
  ["096", "Brunei"],
  ["100", "Bulgaria"],
  ["854", "Burkina Faso"],
  ["108", "Burundi"],
  ["116", "Cambodia"],
  ["120", "Cameroon"],
  ["124", "Canada"],
  ["140", "Central African Rep."],
  ["148", "Chad"],
  ["152", "Chile"],
  ["156", "China"],
  ["170", "Colombia"],
  ["178", "Congo"],
  ["188", "Costa Rica"],
  ["384", "Côte d'Ivoire"],
  ["191", "Croatia"],
  ["192", "Cuba"],
  ["196", "Cyprus"],
  ["203", "Czechia"],
  ["180", "Dem. Rep. Congo"],
  ["208", "Denmark"],
  ["262", "Djibouti"],
  ["214", "Dominican Rep."],
  ["218", "Ecuador"],
  ["818", "Egypt"],
  ["222", "El Salvador"],
  ["226", "Eq. Guinea"],
  ["232", "Eritrea"],
  ["233", "Estonia"],
  ["748", "eSwatini"],
  ["231", "Ethiopia"],
  ["238", "Falkland Is."],
  ["242", "Fiji"],
  ["246", "Finland"],
  ["260", "Fr. S. Antarctic Lands"],
  ["250", "France"],
  ["266", "Gabon"],
  ["270", "Gambia"],
  ["268", "Georgia"],
  ["276", "Germany"],
  ["288", "Ghana"],
  ["300", "Greece"],
  ["304", "Greenland"],
  ["320", "Guatemala"],
  ["324", "Guinea"],
  ["624", "Guinea-Bissau"],
  ["328", "Guyana"],
  ["332", "Haiti"],
  ["340", "Honduras"],
  ["348", "Hungary"],
  ["352", "Iceland"],
  ["356", "India"],
  ["360", "Indonesia"],
  ["364", "Iran"],
  ["368", "Iraq"],
  ["372", "Ireland"],
  ["376", "Israel"],
  ["380", "Italy"],
  ["388", "Jamaica"],
  ["392", "Japan"],
  ["400", "Jordan"],
  ["398", "Kazakhstan"],
  ["404", "Kenya"],
  ["414", "Kuwait"],
  ["417", "Kyrgyzstan"],
  ["418", "Laos"],
  ["428", "Latvia"],
  ["422", "Lebanon"],
  ["426", "Lesotho"],
  ["430", "Liberia"],
  ["434", "Libya"],
  ["440", "Lithuania"],
  ["442", "Luxembourg"],
  ["807", "Macedonia"],
  ["450", "Madagascar"],
  ["454", "Malawi"],
  ["458", "Malaysia"],
  ["466", "Mali"],
  ["478", "Mauritania"],
  ["484", "Mexico"],
  ["498", "Moldova"],
  ["496", "Mongolia"],
  ["499", "Montenegro"],
  ["504", "Morocco"],
  ["508", "Mozambique"],
  ["104", "Myanmar"],
  ["516", "Namibia"],
  ["524", "Nepal"],
  ["528", "Netherlands"],
  ["540", "New Caledonia"],
  ["554", "New Zealand"],
  ["558", "Nicaragua"],
  ["562", "Niger"],
  ["566", "Nigeria"],
  ["408", "North Korea"],
  ["578", "Norway"],
  ["512", "Oman"],
  ["586", "Pakistan"],
  ["275", "Palestine"],
  ["591", "Panama"],
  ["598", "Papua New Guinea"],
  ["600", "Paraguay"],
  ["604", "Peru"],
  ["608", "Philippines"],
  ["616", "Poland"],
  ["620", "Portugal"],
  ["630", "Puerto Rico"],
  ["634", "Qatar"],
  ["642", "Romania"],
  ["643", "Russia"],
  ["646", "Rwanda"],
  ["728", "S. Sudan"],
  ["682", "Saudi Arabia"],
  ["686", "Senegal"],
  ["688", "Serbia"],
  ["694", "Sierra Leone"],
  ["703", "Slovakia"],
  ["705", "Slovenia"],
  ["090", "Solomon Is."],
  ["706", "Somalia"],
  ["710", "South Africa"],
  ["410", "South Korea"],
  ["724", "Spain"],
  ["144", "Sri Lanka"],
  ["729", "Sudan"],
  ["740", "Suriname"],
  ["752", "Sweden"],
  ["756", "Switzerland"],
  ["760", "Syria"],
  ["158", "Taiwan"],
  ["762", "Tajikistan"],
  ["834", "Tanzania"],
  ["764", "Thailand"],
  ["626", "Timor-Leste"],
  ["768", "Togo"],
  ["780", "Trinidad and Tobago"],
  ["788", "Tunisia"],
  ["792", "Turkey"],
  ["795", "Turkmenistan"],
  ["800", "Uganda"],
  ["804", "Ukraine"],
  ["784", "United Arab Emirates"],
  ["826", "United Kingdom"],
  ["840", "United States of America"],
  ["858", "Uruguay"],
  ["860", "Uzbekistan"],
  ["548", "Vanuatu"],
  ["862", "Venezuela"],
  ["704", "Vietnam"],
  ["732", "W. Sahara"],
  ["887", "Yemen"],
  ["894", "Zambia"],
  ["716", "Zimbabwe"],
];

const countryCodeToNameMap = countries.reduce((c, [code, name]) => {
  c[code] = name;
  return c;
}, {});

export function countryCodeToName(code) {
  return countryCodeToNameMap[code] || "Unknown";
}
