import React, { useMemo } from "react";
import { geoEqualEarth, geoPath } from "d3-geo";
import countries from "./countries-110m.json";
import { numericToAlpha2 } from "i18n-iso-countries";
import { feature, mesh } from "topojson-client";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { scaleThreshold } from "d3-scale";
import { schemePuRd } from "d3-scale-chromatic";
import UserTooltip from "./UserTooltip";
import { withContentRect } from "react-measure";
import { countryCodeToName } from "./countries";
import { Box } from "@material-ui/core";
import { MapContext } from "./MapDataProvider";
import Loading from "./Loading";

const mapWidth = 500;
const countriesTopo = {
  ...countries.objects.countries,
  geometries: countries.objects.countries.geometries.filter(
    (f) => f.id !== "010",
  ),
};
const countryFeatures = feature(countries, countriesTopo);
const projection = geoEqualEarth()
  .rotate([-10, 0])
  .fitWidth([mapWidth], countryFeatures);
const path = geoPath(projection);
const bounds = path.bounds(countryFeatures);
const mapHeight = bounds[1][1] - bounds[0][1];
const countryPathsData = countryFeatures.features.map((countryFeature) => ({
  id: countryFeature.id,
  countryAlpha2: numericToAlpha2(countryFeature.id),
  name: countryFeature.properties.name,
  svgPath: path(countryFeature),
}));
const boundariesPath = path(mesh(countries, countriesTopo));
const useStyles = makeStyles(() => ({
  mapBox: {
    backgroundColor: "#fff",
    borderRadius: "1rem",
    padding: "1.5rem",
    height: "100%",
  },
  mapContainer: {
    flex: "1 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    height: "75vh",
  },
  scaleContainer: {
    padding: 5,
    alignSelf: "center",
  },
}));
const MapHeatmap = withContentRect("bounds")(({
  onSelectCountry,
  measureRef,
  contentRect,
}) => {
  const classes = useStyles();
  const { countryCounts, total } = React.useContext(MapContext);
  let { width, height } = contentRect.bounds;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  if (matches) {
    height = (width / mapWidth) * mapHeight;
  }
  const colorScale = useMemo(() => {
    const thresholds = [1, 2, 5, 10, 20, 50, 100];
    const colors = schemePuRd[thresholds.length + 1];
    colors[0] = "#fff";
    return scaleThreshold().domain(thresholds).range(colors);
  }, []);

  if (total === 0) {
    return (
      <Box className={classes.mapBox}>
        <Loading />
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.mapBox}>
        <div ref={measureRef} className={classes.mapContainer}>
          {width && height && (
            <svg
              viewBox={`0 0 ${mapWidth} ${mapHeight}`}
              width={width - 20}
              height={height}
            >
              <g>
                {countryPathsData.map((country, i) => (
                  <UserTooltip
                    key={i}
                    country={countryCodeToName(country.id)}
                    userCount={countryCounts?.[country.countryAlpha2] || 0}
                  >
                    <path
                      fill={colorScale(
                        countryCounts?.[country.countryAlpha2] || 0,
                      )}
                      d={country.svgPath}
                      onClick={() => onSelectCountry(country.countryAlpha2)}
                    />
                  </UserTooltip>
                ))}
              </g>
              <path
                d={boundariesPath}
                fill="none"
                stroke="#cccccc"
                strokeWidth="1"
                vectorEffect="non-scaling-stroke"
              />
            </svg>
          )}
        </div>
        <div>
          <div
            style={{
              display: "inline-block",
              width: "3%",
              backgroundColor: "#f5f5f5",
              color: "#dac8dc",
              textAlign: "right",
              paddingRight: "3px",
              borderRadius: "1rem 0rem 0rem 1rem",
              fontSize: "12px",
            }}
          >
            1
          </div>
          <div
            style={{
              display: "inline-block",
              width: "4%",
              backgroundColor: "#e6e1ee",
              color: "#a882ab",
              textAlign: "right",
              paddingRight: "3px",
              borderRadius: "0rem",
              fontSize: "12px",
            }}
          >
            2
          </div>
          <div
            style={{
              display: "inline-block",
              width: "8%",
              backgroundColor: "#d0bad8",
              color: "#8a6e8b",
              textAlign: "right",
              paddingRight: "3px",
              borderRadius: "0rem",
              fontSize: "12px",
            }}
          >
            5
          </div>
          <div
            style={{
              display: "inline-block",
              width: "9%",
              backgroundColor: "#c196c4",
              color: "#ffffff",
              textAlign: "right",
              paddingRight: "3px",
              borderRadius: "0rem",
              fontSize: "12px",
            }}
          >
            10
          </div>
          <div
            style={{
              display: "inline-block",
              width: "13%",
              backgroundColor: "#d06cad",
              color: "#ffffff",
              textAlign: "right",
              paddingRight: "3px",
              borderRadius: "0rem",
              fontSize: "12px",
            }}
          >
            20
          </div>
          <div
            style={{
              display: "inline-block",
              width: "25%",
              backgroundColor: "#d43e88",
              color: "#ffffff",
              textAlign: "right",
              paddingRight: "3px",
              borderRadius: "0rem",
              fontSize: "12px",
            }}
          >
            50
          </div>
          <div
            style={{
              display: "inline-block",
              width: "28%",
              backgroundColor: "#bd2d57",
              color: "#ffffff",
              textAlign: "right",
              paddingRight: "3px",
              borderRadius: "0rem",
              fontSize: "12px",
            }}
          >
            100
          </div>
          <div
            style={{
              display: "inline-block",
              width: "10%",
              backgroundColor: "#85183f",
              color: "#85183f",
              textAlign: "right",
              paddingRight: "3px",
              borderRadius: "0rem 1rem 1rem 0rem",
              fontSize: "12px",
            }}
          >
            100+
          </div>
        </div>
        {/*<div className={classes.scaleContainer}>
            <ThresholdScaleLegend scale={scale} maxWidth={width} />
                  </div>*/}
      </Box>
    </>
  );
});
export default MapHeatmap;
