import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core";
import Sidebar from "./sidebar/Sidebar";
import { Grid } from "@material-ui/core";
import { useState } from "react";
import MapHeatmap from "./MapHeatmap";
import { MapDataProvider } from "./MapDataProvider";
import Menu from "./Menu";

const defaultTheme = createTheme();
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1536,
    },
  },
  palette: {
    type: "light",
    background: {
      default: "hsl(210, 14%, 97%)",
    },
    primary: {
      main: "#323334",
    },
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
    h1: {
      //Artist Name
      fontFamily: ["Lexend Deca", "sans-serif"].join(","),
      color: `hsl(210, 2%, 20%)`, //palette.primary.main
      fontSize: "28px",
      fontWeight: 700,
      letterSpacing: "normal",
    },
    h2: {
      //Login, Sign up
      fontFamily: ["Lexend Deca", "sans-serif"].join(","),
      color: `hsl(210, 2%, 20%)`, //palette.primary.main
      fontSize: "20px",
      fontWeight: 700,
      letterSpacing: "normal",
    },
    h3: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      textTransform: "Capitalize",
      fontWeight: "500",
      fontSize: 20,
      color: `hsl(210, 2%, 20%)`, //palette.primary.main
    },
    h4: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontWeight: "500",
      fontSize: 16,
      color: `hsl(210, 2%, 40%)`, //palette.primary.hint
    },
    h5: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      color: `hsl(210, 2%, 20%)`, //palette.primary.main
      fontSize: 14,
      fontWeight: 700,
    },
    body1: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: 14,
      color: `hsl(210, 2%, 20%)`, //palette.primary.main
    },
    body2: {
      fontSize: 14,
      color: "#646668", //palette.primary.main
    },
    subtitle1: {
      fontSize: 12,
      lineHeight: 1.5,
      color: "hsl(210, 2%, 20%)", //palette.primary.main
    },
    help: {
      height: "16px",
      color: "hsl(210, 34%, 47%)", //palette.blue.dark
      width: "100%",
      textAlign: "left",
      alignItems: "center",
      fontFamily: "Heebo, Sans-serif",
      fontSize: "12px",
      display: "flex",
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
          [defaultTheme.breakpoints.down("sm")]: {
            overflowY: "scroll",
          },
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.only("xl")]: {
      paddingRight: "4rem",
      paddingLeft: "4rem",
    },
    [theme.breakpoints.only("lg")]: {
      paddingRight: "4rem",
      paddingLeft: "4rem",
    },
    [theme.breakpoints.only("md")]: {
      paddingRight: "4rem",
      paddingLeft: "4rem",
    },
    [theme.breakpoints.only("sm")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    [theme.breakpoints.only("xs")]: {
      paddingLeft: "0rem",
      paddingRight: "0rem",
    },
    marginTop: "17px",
    marginBottom: "1rem",
  },
  main: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 88px)",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
    },
    [theme.breakpoints.up("md")]: {
      height: "100vh",
    },
  },
  header: {
    padding: "1rem",
    [theme.breakpoints.up("md")]: {
      padding: "1rem 4rem",
    },
  },
  button: {
    color: "black",
    backgroundColor: "white",
  },
  box: {
    height: "100%",
  },
}));

function App() {
  const classes = useStyles();
  const [countryAlpha2, setCountryAlpha2] = useState();
  return (
    <MapDataProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <div className={classes.header}>
            <Menu />
          </div>
          <main>
            <div className={classes.root}>
              <Grid container spacing={4} className={classes.main}>
                <Grid item xs={12} md={8}>
                  <MapHeatmap onSelectCountry={setCountryAlpha2} />
                </Grid>
                <Grid item xs={12} md={4} className={classes.box}>
                  <Sidebar
                    countryCode={countryAlpha2}
                    onSelectCountry={setCountryAlpha2}
                  />
                </Grid>
              </Grid>
            </div>
          </main>
        </CssBaseline>
      </ThemeProvider>
    </MapDataProvider>
  );
}

export default App;
