import {
  Box,
  Button,
  Card,
  Divider,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import Loading from "../Loading";
import { ReactComponent as LogoBullet } from "../logo.svg";
import { MapContext } from "../MapDataProvider";

countries.registerLocale(enLocale);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    borderRadius: "1rem",
    padding: "1.5rem",
    maxHeight: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    paddingBottom: 40, // Add space for the footer
  },
  nameBlock: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    overflow: "hidden",
  },
  card: {
    backgroundColor: "#eef0f2",
    borderRadius: "0.75rem",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardContent: {
    padding: "1rem",
  },
  publicPageLink: {
    "&:not(:last-child)": {
      marginBottom: 2,
    },
  },
  logoBullet: {
    width: 20,
    height: 20,
    marginRight: 5,
    verticalAlign: "middle",
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
    borderRadius: 28,
    fontSize: "14px",
    fontWeight: "400",
    color: "#646668",
    padding: "0.5rem 1rem",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0.67px 0.67px 4px 0 rgba(0,0,0,0.1)",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: "#D3D3D3",
    },
  },
}));
const Sidebar = ({ countryCode, onSelectCountry }) => {
  const classes = useStyles();
  const { users, countryCounts, total } = useContext(MapContext);
  const userCount = countryCode ? countryCounts[countryCode] || 0 : total;
  const numCountries = Object.keys(countryCounts || {}).length;
  return (
    <section className={classes.root}>
      <Box mb={2}>
        <Typography variant="h4">Community Map</Typography>
      </Box>
      <Typography variant="h5">
        Latest users in{" "}
        {countryCode != null
          ? countries.getName(countryCode, "en", { select: "alias" })
          : "all countries"}
      </Typography>
      {total !== 0 && (
        <Typography variant="body1">
          {userCount + " "}
          {userCount === 1 ? "user" : "users"}
          {countryCode ? "" : ` in ${numCountries} countries`}
        </Typography>
      )}
      {countryCode && (
        <Button className={classes.button} onClick={() => onSelectCountry()}>
          View all countries
        </Button>
      )}
      {total > 0 ? (
        <>
          {users
            .filter((u) => !countryCode || countryCode === u.country_alpha2)
            .map((user) => (
              <section key={user.persona_id} className={classes.nameBlock}>
                <Card elevation={0} className={classes.card}>
                  <div className={classes.cardContent}>
                    <Typography className={classes.title} variant="h5">
                      {user.artist_name}
                    </Typography>
                    <Typography variant="body1">
                      {countries.getName(user.country_alpha2, "en", {
                        select:
                          user.country_alpha2 === "GB" ? "official" : "alias",
                      })}
                    </Typography>
                  </div>
                  <>
                    <Divider />
                    <div className={classes.cardContent}>
                      <div className={classes.publicPageLink}>
                        <LogoBullet className={classes.logoBullet} />
                        <Link
                          href={`https://creativepassport.net/page/${user.passport_id}/${user.persona_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {user.artist_name}
                        </Link>
                      </div>
                    </div>
                  </>
                </Card>
              </section>
            ))}
        </>
      ) : (
        <Box mt={4}>
          <Loading />
        </Box>
      )}
    </section>
  );
};
export default Sidebar;
